/* Built In Imports */
import { useEffect } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Services */

/**
 * Renders the UpcomingProcesses component.
 * @returns {ReactElement} Program Finder component.
 */
// const UpcomingProcesses = () => {
//   const getScript = url =>
//     new Promise((resolve, reject) => {
//       const script = document.createElement('script');
//       script.src = url;
//       script.async = true;
//       script.onerror = reject;
//       script.onload = script.onreadystatechange = function () {
//         const loadState = this.readyState;
//         if (loadState && loadState !== 'loaded' && loadState !== 'complete')
//           return;
//         script.onload = script.onreadystatechange = null;
//         resolve();
//       };

//       document.head.appendChild(script);
//     });
//   const loadProcessSchedule = () => {
//     PrsSchedule.initFrame({
//       divId: 'processFinder',
//       title: 'Process Finder',
//       minHeight: '320',
//       background: 'faf7f0',
//       type: 'in-person',
//       category: 'ay-processes',
//       center: 'Isha Institute of Inner-sciences',
//       minimal: 1,
//     });
//   };

//   useEffect(() => {
//     if (typeof PrsSchedule === 'undefined') {
//       getScript(
//         (location.origin == 'https://isha.sadhguru.org'
//           ? 'https://online.innerengineering.com/'
//           : 'https://staging.innerengineering.com/') +
//           'schedule-assets/js/embed.js'
//       ).then(loadProcessSchedule);
//     } else {
//       loadProcessSchedule();
//     }

//     return () => {
//       // document.removeChild(script);
//     };
//   }, []);

//   return <Box id="processFinder"></Box>;
// };
/* Built In Imports */

/* External Imports */

/* Internal Imports */
/* Services */

/**
 * Renders the UpcomingProcesses component.
 * @returns {ReactElement} Program Finder component.
 */
const UpcomingProcesses = () => {
  const getScript = url =>
    new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.onerror = reject;
      script.onload = script.onreadystatechange = function () {
        const loadState = this.readyState;
        if (loadState && loadState !== 'loaded' && loadState !== 'complete')
          return;
        script.onload = script.onreadystatechange = null;
        resolve();
      };

      document.head.appendChild(script);
    });
  const loadProcessSchedule = () => {
    PrsSchedule.initFrame({
      divId: 'processFinder',
      title: 'Process Finder',
      minHeight: '320',
      background: 'faf7f0',
      type: 'in-person',
      category: 'ay-processes',
      center: 'Isha Institute of Inner-sciences',
      minimal: 1,
    });
  };

  useEffect(() => {
    if (typeof PrsSchedule === 'undefined') {
      getScript(
        (location.origin == 'https://isha.sadhguru.org'
          ? 'https://online.innerengineering.com/'
          : 'http://staging.innerengineering.com/') +
          'schedule-assets/js/embed.js'
      ).then(loadProcessSchedule);
    } else {
      loadProcessSchedule();
    }

    return () => {
      // document.removeChild(script);
    };
  }, []);

  return <Box id="processFinder"></Box>;
};

export default UpcomingProcesses;





